/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  var agenda_scripts = function (){
    $('.b-agendalist__item').each(function() {
      if ($(this).index() > 0 && $(this).index() % 2 > 0) {
        var rightBlockTopMargin = $('.b-agendalist__item').eq(($(this).index() - 1)).height() / 2;
        $(this).css('margin-top', rightBlockTopMargin);
      }
    });

    $('.b-agendalist__item').each(function() {
      if ($(this).index() > 0 && $(this).index() % 2 === 0) {
        var leftBlockTopMargin = $('.b-agendalist__item').eq(($(this).index() - 1)).height() / 2;
        $(this).css('margin-top', '-60px');
      }
    });

    $("input[name=calendar_month]:checked").click(function (){
      $(this).prop("checked", false);
      $("form[name=filterby]").submit();
    });

    $("input[name=calendar_year]:checked").click(function (){
      $(this).prop("checked", false);
      $("input[name=calendar_month]:checked").prop("checked", false);
      $("form[name=filterby]").submit();
    });
  };
  var faces_scripts = function (){
  		//чистим блок с фотками и получаем его размеры
		var photoBlock=$('.b-collage-photo');
		var photoBlockWidth=photoBlock.width();
		var photoBlockHeight=photoBlock.height();
		photoBlock.html('');

		//параметры превьюшек
		var previewWidth=150;
 		var previewHeight=150;
		var zoomDelta=100;
		var previewStepY=75;
		var previewStepX=75;//(photoBlockWidth-zoomDelta-previewWidth)/($('.b-collage-data__item').length);
		var prevY=0;
		var collageDataRel=1;

		$.each( $('.b-collage-data__item'), function(){
			randomZ=Math.floor(Math.random()*10000)+80000;
			randomX=Math.floor((collageDataRel-1)*previewStepX)+zoomDelta/2;   //Math.floor(Math.random()*((photoBlock.width()-previewWidth)/previewStep))*previewStep;
			randomY=Math.floor(Math.random()*((photoBlock.height()-previewHeight-zoomDelta)/previewStepY))*previewStepY+zoomDelta/2;
			while (randomY===prevY)	{
				randomY=Math.floor(Math.random()*((photoBlock.height()-previewHeight-zoomDelta)/previewStepY))*previewStepY+zoomDelta/2;
			}
			prevY=randomY;
			photoBlock.append('<div collage-data-left="'+randomX+'" collage-data-top="'+randomY+'" collage-data-zindex="'+randomZ+'" collage-data-rel="'+collageDataRel+'" class="b-collage-photo__item" style="z-index:'+randomZ+';left:'+randomX+'px;top:'+randomY+'px">'+$(this).find('.photo').html()+'</div>');
			$(this).attr('collage-data-rel',collageDataRel);
			collageDataRel++;

			});
		var facesObj;

		$('.b-collage-photo__item img').mouseover(function(){
				if (facesObj) {
					facesObj.parent().stop().animate({'width':previewWidth,'height':previewHeight,'opacity':1,'top':facesObj.parent().attr('collage-data-top'),'left':facesObj.parent().attr('collage-data-left')},200);
					facesObj.stop().animate({'width':previewWidth,'height':previewHeight},200);
					facesObj.parent().css({'z-index':facesObj.parent().attr('collage-data-zindex')});
					facesObj.css('border-color','white');
					}
				$('.b-collage-text').html( $('.b-collage-data__item[collage-data-rel="'+$(this).parent().attr('collage-data-rel')+'"] .text').html() );
				$(this).css('border-color','#48a74d');
				$(this).parent().stop().animate({'width':previewWidth+zoomDelta,'height':previewHeight+zoomDelta,'opacity':1,'top':$(this).parent().attr('collage-data-top')-zoomDelta/2,'left':$(this).parent().attr('collage-data-left')-zoomDelta/2},200);
				$(this).stop().animate({'width':previewWidth+zoomDelta,'height':previewHeight+zoomDelta},200);
				$(this).parent().css({'z-index':99999});
				facesObj=$(this);
			});
  };
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.social-likes').socialLikes();
        // Показ меню для страниц третьего уровня
        if ($("ul.sub-menu > li").hasClass("current-page-ancestor")) {
          $(".sub-menu .current-page-ancestor").addClass("current-menu-item");
          $(".b-header").removeClass("b-header_menu-level-1");
          $(".b-header").addClass("b-header_menu-level-2");
          $('.current-menu-item').parent().parent().addClass("current-menu-parent");
        }

        $("li:has(li.current-post-parent)").addClass("current-menu-parent");
        $(".sub-menu > li.current-post-parent").first().addClass("current-menu-item");

        // фикс ширины меню второго уровня

        $('.current-menu-item .sub-menu, .current-menu-parent .sub-menu').width($('.b-page').width());
        $(window).resize(function(){
          $('.current-menu-item .sub-menu, .current-menu-parent .sub-menu').width($('.b-page').width());
        });

        // фикс ширины подвала на sm и xs
      	$('.b-footer-wrapper').width(Math.max($('body').width(),$('.b-wrapper').width()+30));
      	$(window).resize(function(){
      		$('.b-footer-wrapper').width(Math.max($('body').width(),$('.b-wrapper').width()+30));
      	});

        var quicksearch_focused=false;

        $('.b-icons-menu__item_search').mouseover(function(){
      		$('.b-quicksearch').stop().addClass('b-quicksearch_active');
      	}).mouseout(function(){
      		if (!quicksearch_focused) {
            $('.b-quicksearch').stop().removeClass('b-quicksearch_active');
          }
      	});
      	$('.b-quicksearch__input').focus(function(){
      		quicksearch_focused=true;
      	}).blur(function(){
      		quicksearch_focused=false;
      		$('.b-quicksearch').stop().removeClass('b-quicksearch_active');
      	});

      	$('.b-icons-menu__item_search a').click(function(e){
      		e.preventDefault();
      		if ($('.b-quicksearch__input').val().length>0) {
            $('#quicksearch').submit();
          }
      		else {
            document.location.href=$('.b-icons-menu__item_search a').attr('href');
          }
      	});

        // Fix header height

        if ($(".header-menu li").hasClass("current-menu-item")) {
          $(".b-header").removeClass("b-header_menu-level-1");
          $(".b-header").addClass("b-header_menu-level-2");
          $('.current-menu-item > .sub-menu').addClass("b-menu-level-2");
        } else {
          $(".b-header").removeClass("b-header_menu-level-2");
          $(".b-header").addClass("b-header_menu-level-1");
          $('.b-header_menu-level-2').removeClass("b-menu-level-2");
        }

        // показ меню сайта
      	$('.b-icons-menu__hamburger').bind('click',function(e){
      		e.preventDefault();
      		if ($(this).hasClass('b-icons-menu__hamburger_visible')) {
      			$(this).removeClass('b-icons-menu__hamburger_visible');
      			$('.header-menu').removeClass('b-menu-level-1_visible');
      		}
      		else {
      			$(this).addClass('b-icons-menu__hamburger_visible');
      			$('.header-menu').addClass('b-menu-level-1_visible');
      		}
      	});
        $("ul.header-menu > li:not(.current-menu-item) > a").append('<span class="hidden-sm hidden-md hidden-lg glyphicon glyphicon-chevron-right"></span>');
        $("ul.header-menu > li.current-menu-item > a").append('<span class="hidden-sm hidden-md hidden-lg glyphicon glyphicon-chevron-down"></span>');

        // раскрывашка b-content-group
        $('.b-content-group__title').click(function(){
          var curobj=$(this).parent();
          if (curobj.hasClass('b-content-group_active')) {
            curobj.find(".b-content-group__content").slideUp('fast');
            curobj.removeClass('b-content-group_active');
            }
          else {
            curobj.find(".b-content-group__content").slideDown('fast');
            $('html,body').animate({scrollTop:curobj.offset().top},'fast');
            curobj.addClass('b-content-group_active');
            }
        });

        // автораскрывашка при клике по левому сабменю
        $('.b-content-menu_content-group-autoopen ul li a').click(function(){
          $('html,body').animate({scrollTop:$('#'+$(this).attr('href').substr(1)).find(".b-content-group__content").parent().offset().top},'fast');
          $('#'+$(this).attr('href').substr(1)).find(".b-content-group__content").slideDown('fast').parent().addClass('b-content-group_active');
        });

		// раскрывашка документов и календаря в ней 06/11/2015
		// прототип scrollTo
		$.fn.scrollTo = function( target, options, callback ){
		  if(typeof options === 'function' && arguments.length === 2){ callback = options; options = target; }
		  var settings = $.extend({
			scrollTarget  : target,
			offsetTop     : 50,
			duration      : 500,
			easing        : 'swing'
		  }, options);
		  return this.each(function(){
			var scrollPane = $(this);
			var scrollTarget = (typeof settings.scrollTarget === "number") ? settings.scrollTarget : $(settings.scrollTarget);
			var scrollY = (typeof scrollTarget === "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
			scrollPane.animate({scrollTop : scrollY }, parseInt(settings.duration), settings.easing, function(){
			  if (typeof callback === 'function') { callback.call(this); }
			});
		  });
		};

		// восстановление состояния из хэш-части url-а
		if ($('.b-big-documents-list').length>0 && document.location.hash.length>0) {
		var hashstr=document.location.hash.substring(1);
		var hashdata=hashstr.split('-');

		if ($('#'+hashdata[0]).length>0) {
			$('.b-big-documents-list').removeClass('b-big-documents-list_active');
			$('#'+hashdata[0]).addClass('b-big-documents-list_active');
			if (hashdata[1]) {
				$('#'+hashdata[0]+' .b-big-documents-list__calendar ul li').removeClass('active');
				$('#'+hashdata[0]+' .b-big-documents-list__calendar ul li a[data-year="'+hashdata[1]+'"]').parent().addClass('active');
				$('#'+hashdata[0]+' .b-big-documents-list__item').parent().hide();
				$('#'+hashdata[0]+' .b-big-documents-list__item[data-year="'+hashdata[1]+'"]').parent().show();
				}
			$('body').scrollTo('#'+hashdata[0]);
			}
		}

        // новая скрывашка-раскрывашка для документов
        $('.b-big-documents-list h2 a').click(function(e){
          e.preventDefault();
          var curobj=$(this).closest('.b-big-documents-list');
          if (curobj.hasClass('b-big-documents-list_active')) {
          }
          else {
            $('.b-big-documents-list .b-big-documents-list__wrapper').slideUp('fast',function(){$(this).parent().removeClass('b-big-documents-list_active');});
            curobj.find('.b-big-documents-list__wrapper').slideDown('fast');
            curobj.addClass('b-big-documents-list_active');
			document.location.hash=curobj.attr('id');
          }
        });

		// переключение годов
		$('.b-big-documents-list__calendar ul li a').click(function(e){
			$('.b-big-documents-list__calendar ul li').removeClass('active');
			$(this).parent().addClass('active');
			$(this).closest('.raw').find('div[data-year]').parent().hide();
			$(this).closest('.raw').find("div[data-year='"+$(this).attr('data-year')+"']").parent().show();
			document.location.hash=$(this).closest('.b-big-documents-list').attr('id')+'-'+$(this).attr('data-year');
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on home page
        $(".slides").find("img").click(function() {
          link = $(this).parent().find("a");
          link.click();
          window.location.href = link.attr("href");
        });
      },
      finalize: function() {
        // JavaScript to be fired on home page, after page specific JS is fired
      }
    },
    'agenda': {
      init: function() {
        agenda_scripts();
      }
    },
    'agenda_en': {
      init: function() {
        agenda_scripts();
      }
    },
    'faces': {
      init: function() {
		faces_scripts();
      }
    },
    'faces_en': {
      init: function() {
		faces_scripts();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function(){

// первоначально выставляем футер
	$('.b-wrapper').css('min-height',0);
	if ( ($('.b-wrapper').height()+$('.b-footer-wrapper').height())<$("html").height()) {
	$('.b-wrapper').css('min-height',$("html").height()-$('.b-footer-wrapper').height()+0);
	}

//  треким ресайз, при изменениях пересчитываем положение футера
	$(window).bind('resize', function(e){
	    $('.b-wrapper').css('min-height',0);
   		if ( $('.b-wrapper').height()+$('.b-footer-wrapper').height()<$("html").height() ) {
		$('.b-wrapper').css('min-height',$("html").height()-$('.b-footer-wrapper').height()+0);
		}
	});

});

})(jQuery); // Fully reference jQuery after this point.
